import { Controller } from "stimulus"
import _isEmpty from 'lodash/isEmpty'
import flatpickr from 'flatpickr'

export default class extends Controller {
  static targets = ['input', 'resetButton', 'todayButton']

  get defaultServerDateFormat() { return 'Y-m-d' }
  get defaultFriendlyDateFormat() { return 'm/d/Y' }

  get serverDateFormat() {
    if (_isEmpty(this.element.dataset.dateFormat)) {
      return this.defaultServerDateFormat
    } else {
      return this.element.dataset.dateFormat
    }
  }

  get friendlyDateFormat() {
    if (_isEmpty(this.element.dataset.friendlyDateFormat)) {
      return this.defaultFriendlyDateFormat
    } else {
      return this.element.dataset.friendlyDateFormat
    }
  }

  get minDate() {
    if (_isEmpty(this.data.get('minDate'))) {
      return undefined
    } else {
      return this.data.get('minDate')
    }
  }

  initialize() {
    let targetElement = this.element
    if (this.hasInputTarget) {
      targetElement = this.inputTarget
      targetElement.dataset.input = true
    }

    let flatpickrOptions = {
      allowInput: true,
      altInput: true,
      altFormat: this.friendlyDateFormat,
      dateFormat: this.serverDateFormat,
      defaultDate: targetElement.value,
      disableMobile: false,
      minDate: this.data.get('minDate'),
      wrap: (this.hasInputTarget && this.inputTarget != this.element),
      onChange: (selectedDates, dateStr, instance) => {
        this.element.dispatchEvent(
          new CustomEvent('datepickerChanged', {
            detail: {
              element: this.element,
              inputElement: targetElement,
              selectedDates: selectedDates,
              dateStr: dateStr,
              instance: instance,
            }, bubbles: true
          })
        )
      }
    }

    this.datepicker = flatpickr(this.element, flatpickrOptions)
  }

  open(event) {
    event.preventDefault()
    this.datepicker.open()
  }

  close(event) {
    if (event && event.type == "keyup" && event.code == "Escape") {
      this.datepicker.close()

      if (this.hasInputTarget) {
        this.inputTarget.blur()
      } else {
        this.element.blur()
      }
    }
  }

  setDate(event) {
    event.preventDefault()
    this.datepicker.setDate(event.detail.dateStr, false) //, event.detail.dateStrFormat)
  }

  reset(event) {
    event.preventDefault()
    this.datepicker.clear()
  }

  today(event) {
    event.preventDefault()
    this.datepicker.setDate('today', true)
  }
}

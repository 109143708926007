import { Controller } from "stimulus"
import _isEmpty from 'lodash/isEmpty'

export default class extends Controller {
  static targets = [
    'timeEntryField',
    'openRadio', 'closeRadio',
    'openFields', 'closeFields',
    'closedDateRangeField'
  ]

  initialize() {
    this.render()
  }

  render() {
    if (!this.hasOpenRadioTarget && !this.hasCloseRadioTarget) { return }

    this.openFieldsTargets.forEach((target) => {
      target.querySelectorAll('input').forEach((input) => {
        input.classList.toggle('disabled', this.closeRadioTarget.checked)
        input.classList.toggle('disabled--obfuscate', this.closeRadioTarget.checked)
        input.toggleAttribute('disabled', this.closeRadioTarget.checked)
        input.toggleAttribute('readonly', this.closeRadioTarget.checked)
      })

      target.classList.toggle('hidden', this.closeRadioTarget.checked)
    })

    this.closeFieldsTargets.forEach((target) => {
      target.querySelectorAll('input').forEach((input) => {
        input.classList.toggle('disabled', this.openRadioTarget.checked)
        input.classList.toggle('disabled--obfuscate', this.openRadioTarget.checked)
        input.toggleAttribute('disabled', this.openRadioTarget.checked)
        input.toggleAttribute('readonly', this.openRadioTarget.checked)
      })

      target.classList.toggle('hidden', this.openRadioTarget.checked)
    })
  }
}
